/**
 * Main JS
 */

(function($) {

  // Hide Header on on scroll down
  let didScroll = true,
      lastScrollTop = 0,
      delta = 15,
      header = $('header.header'),
      navbarHeight = 90;//header.outerHeight();


  /**
   * Shrink header on scroll.
   */
  function hasScrolled() {
    let st = $(this).scrollTop(),
        win_w = $(window).width();

    // if (header.hasClass('static-header')) {
    //   return false;
    // }

    //Do not do for static header
    if (!header.hasClass('static-header')) {

      if (parseInt(st) <= (navbarHeight) ) {
        header.removeClass('header-scrolled');
      } else {
        header.addClass('header-scrolled');
      }

    }

    if (lastScrollTop > 0) {
      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      //Desktop only styles:
      if (parseInt(win_w) > 767) {

        // If they scrolled down and are past the delta threshold close the slideout nav.
        if (st > lastScrollTop && st > navbarHeight && $('.slide-out-nav-button').hasClass('is-active')) {
          $('.slide-out-nav-button').trigger('click');
        }

      } else {//end //Desktop only styles:

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.

        //Do not do this if static header
        if (!header.hasClass('static-header')) {
          if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            header.removeClass('nav-down').addClass('nav-up');
          } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
              header.removeClass('nav-up').addClass('nav-down');
            }
          }
        }



      }//end mobile only styles.

    }//end last scroll > 0


    lastScrollTop = st;
  }//end hasScrolled


  /**
   * Set the slide out size for the nav menu
   */
  function set_slide_out() {

    let slide_out = $('span.slide-out');
    if ($('body').hasClass('menu-active')) {

      //Get the left position of the hamburger menu plus 350 for menu space:
      let btn_offset_left = parseInt($('.slide-out-nav-button').offset().left) + 350;

      //Set the width
      slide_out.width(btn_offset_left);
    } else {
      slide_out.width(0);
    }

  }//end set_slide_out


  /**
   * Ensure the lang switcher on mobile animated in AFTER the nav items:
   */
  function set_mobile_lang_switcher_animation () {

    let lang_switcher = $('.theme-lang-switcher.mobile');
    if ($('body').hasClass('menu-active')) {
      let nav_items_count = $('ul.navbar-nav > li').length,
          timeout = (parseInt(nav_items_count + 1) * 0.125) * 1000;

      setTimeout(function () {
        lang_switcher.addClass('lang-switcher-show');
      }, timeout);
    } else {
      lang_switcher.removeClass('lang-switcher-show');
    }

  }//end set_mobile_lang_switcher_animation


  /**
   * Set the heading height for each column-section column to the max height to get a horizontal layout
   */
  function set_content_cols_alignment () {
    let content_col_section = $('section.columns-section');
    if (!content_col_section.length) {
      return;
    }

    content_col_section.each(function () {

      let $this = $(this),
          heading = $this.find('.heading-wrap'),
          post_excerpt = $this.find('div.post-excerpt');

      if (heading.length) {

        let min_h = 0;
        heading.each(function () {
          //Remove any previously set height value
          $(this).removeAttr('style');
          if ($(this).height() > min_h) {
            min_h = $(this).height();
          }
        });

        heading.height(min_h);
      }


      //For items with excerpts - set min height:
      if (post_excerpt.length) {
        let min_h = 0;
        post_excerpt.each(function () {

          //Remove any previously set height value
          $(this).removeAttr('style');
          if ($(this).outerHeight() > min_h) {
            min_h = $(this).height();
          }
        });

        post_excerpt.height(min_h);
      }


    });
  }//end set_content_cols_alignment


  /**
   * Set the content / image section images to the same height as the content
   */
  function set_content_image_section_img_height () {

    //Set the main image. This can be no taller than the content. With a min height of 38vh
    let sections = $('.content-image-section');
    if (sections.length) {

      sections.each(function(){
        let $this = $(this),
            content_h = parseInt($this.find('.theme-wysiwyg').outerHeight());

        //Set the main image heights:
        $this.find('.main-img').css('height', content_h);

        //Set the sub img height
        if ($this.find('.sub-img').length) {
          $this.find('.sub-img').css('height', content_h);
        }

      });

    }//end if section len

  }//end set_content_image_section_img_height


  /**
   * Initialise parallax using scroll magic
   */
  function parallax_init () {
    if ($('.parallax').length) {

      $('.parallax').each(function(){

        let parallax_controller = new ScrollMagic.Controller(),
            elem = $(this),
            inner = elem.find('div.parallax-wrapper > div');

        //To or from?
        if (elem.hasClass('parallax-from')) {
          let slideParallaxScene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 1,
            duration: "100%"
          })
            .setTween(TweenMax.from(inner, 1, {y: '-40%', autoAlpha: 0.3, ease: Power0.easeNone}))
            .addTo(parallax_controller)/*.addIndicators()*/;
        } else {
          let slideParallaxScene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0,
            duration: "100%"
          })
            .setTween(TweenMax.to(inner, 1, {y: '-20%', autoAlpha: 0.1, ease: Power0.easeNone}))
            .addTo(parallax_controller)/*.addIndicators()*/;
        }

      });

    }//single img parallax
  }//end parallax_init


  /**
   * Add aria-labels to owl-carousel buttons
   */
  function set_owl_buttons_aria () {
    let carousels = $('.owl-carousel');
    if (carousels.length) {
      carousels.each(function(){
        let dots_and_nav = $(this).find('div.owl-nav > button, div.owl-dots > button');
        if (dots_and_nav.length) {
          dots_and_nav.each(function(){
            let $this = $(this);
            if ($this.hasClass('owl-prev')) {
              $this.attr('aria-label', 'Previous');
            } else if ($this.hasClass('owl-next')) {
              $this.attr('aria-label', 'Next');
            } else {
              $this.attr('aria-label', 'Next');
            }
          });
        }
      });
    }

    carousels.find('button[class^="owl-"]').each(function(index) {
      $(this).attr('role', 'button');
    });
  }//end set_nav_role_attr


  /**
   * initMap
   *
   * Renders a Google Map onto the selected jQuery element
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   jQuery $el The jQuery element.
   * @return  object The map instance.
   */
  function initMap($el) {

    // Find marker elements within map.
    let $markers = $el.find('.marker');

    // Create generic map.
    let mapArgs = {
      zoom: $el.data('zoom') || 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      mapTypeControl: false
    };

    let args = {
      zoom: $el.data('zoom') || 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      mapTypeControl: false,
      styles: [
	    {
	        "featureType": "administrative",
	        "elementType": "labels.text.fill",
	        "stylers": [
	            {
	                "color": "#444444"
	            }
	        ]
	    },
	    {
	        "featureType": "landscape",
	        "elementType": "all",
	        "stylers": [
	            {
	                "color": "#e3e3e3"
	            }
	        ]
	    },
	    {
	        "featureType": "landscape",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "color": "#e3e3e3"
	            }
	        ]
	    },
	    {
	        "featureType": "landscape",
	        "elementType": "labels",
	        "stylers": [
	            {
	                "color": "#e3e3e3"
	            }
	        ]
	    },
	    {
	        "featureType": "landscape",
	        "elementType": "labels.text.stroke",
	        "stylers": [
	            {
	                "color": "#58585a"
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "all",
	        "stylers": [
	            {
	                "saturation": -100
	            },
	            {
	                "lightness": 45
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "geometry",
	        "stylers": [
	            {
	                "visibility": "on"
	            },
	            {
	                "color": "#ffffff"
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "geometry.stroke",
	        "stylers": [
	            {
	                "visibility": "on"
	            },
	            {
	                "color": "#e3e3e3"
	            }
	        ]
	    },
	    {
	        "featureType": "road",
	        "elementType": "labels.icon",
	        "stylers": [
	            {
	                "hue": "#0000ff"
	            },
	            {
	                "saturation": "-13"
	            },
	            {
	                "lightness": "78"
	            },
	            {
	                "gamma": "1.53"
	            },
	            {
	                "weight": "0.01"
	            }
	        ]
	    },
	    {
	        "featureType": "road.highway",
	        "elementType": "all",
	        "stylers": [
	            {
	                "visibility": "simplified"
	            }
	        ]
	    },
	    {
	        "featureType": "road.arterial",
	        "elementType": "labels.icon",
	        "stylers": [
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "transit",
	        "elementType": "all",
	        "stylers": [
	            {
	                "visibility": "off"
	            }
	        ]
	    },
	    {
	        "featureType": "water",
	        "elementType": "all",
	        "stylers": [
	            {
	                "color": "#dbf7ff"
	            },
	            {
	                "visibility": "on"
	            }
	        ]
	    },


			{
				"featureType": "poi.business",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi.government",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi.medical",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi.place_of_worship",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi.school",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi.sports_complex",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			}
      ]
    };
    let map = new google.maps.Map($el[0], args);

    // Add markers.
    map.markers = [];
    $markers.each(function () {
      initMarker($(this), map);
    });

    // Center map based on markers.
    centerMap(map);

    // Return map instance.
    return map;
  }//end init map


  /**
   * initMarker
   *
   * Creates a marker for the given jQuery element and map.
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   jQuery $el The jQuery element.
   * @param   object The map instance.
   * @return  object The marker instance.
   */
  function initMarker($marker, map) {

    // Get position from marker.
    let lat = $marker.data('lat');
    let lng = $marker.data('lng');
    let latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    };

    // Create marker instance.
    let marker = new google.maps.Marker({
      position: latLng,
      map: map,
      icon: {
        url: $marker.data('pin-icon'),
        scaledSize: new google.maps.Size(50, 50)
      }
    });

    // Append to reference for later use.
    map.markers.push(marker);

    // If marker contains HTML, add it to an infoWindow.
    if ($marker.html()) {

      // Create info window.
      let infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // Show info window when marker is clicked.
      google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });
    }
  }//end initMarker


  /**
   * centerMap
   *
   * Centers the map showing all markers in view.
   *
   * @date    22/10/19
   * @since   5.8.6
   *
   * @param   object The map instance.
   * @return  void
   */
  function centerMap(map) {

    // Create map boundaries from all map markers.
    let bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function (marker) {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng()
      });
    });

    // Case: Single marker.
    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter());

      // Case: Multiple markers.
    } else {
      map.fitBounds(bounds);
    }
  }//end centerMap


  /**
   * Add AOS anchor trigger class to shortcode AOS elems
   */
  function set_wysiwyg_aos_elem_trigger () {

    let sections = $('main > section');
    if (sections.length) {

      sections.each(function(){

        let $this = $(this),
            aos_elems = $this.find('span.animated-text-shortcode, span.text-large-shortcode, span.btn-has-aos, span.heading-size-shortcode');

        if (aos_elems.length) {
          let section_class = 'aos-anchor-parent-'+Math.random().toString(16).slice(2);
          $this.addClass(section_class);
          aos_elems.attr('data-aos-anchor', '.'+section_class);
        }

      });

    }//end if (sections.length) {

  }//end set_wysiwyg_aos_elem_trigger





  $(function() {


    //Set AOS triggers on anchor elems
    set_wysiwyg_aos_elem_trigger();

    //Set content / image section image height
    set_content_image_section_img_height();

    //Set content cols alignment
    set_content_cols_alignment();

    //Init AOS
    if (typeof AOS !== 'undefined') {
      AOS.init();
    }

    //Init lightbox
    if (typeof GLightbox !== 'undefined') {
      let lightbox = GLightbox({
        openEffect: 'fade',
        closeEffect: 'fade',
        slideEffect: 'fade',
        loop: true
      });
    }





    //Init parallax
    parallax_init();


    //OnResize
    $(window).on('resize', function () {

      //Resize the slide out width
      set_slide_out();

      //Content col alignment
      set_content_cols_alignment();

      //Content / img sections
      set_content_image_section_img_height();

    });


    //OnScroll
    $(window).on('scroll', function () {
      didScroll = true;
    });//end on Scroll
    setInterval(function () {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);


    //Nav click
    $('button.slide-out-nav-button').click(function(e){

      e.preventDefault();

      let $this = $(this);

      //Show
      if (!$this.hasClass('is-active')) {
        $('body').addClass('menu-active');
        set_slide_out();
        set_mobile_lang_switcher_animation();
        $this.attr('aria-expanded', 'true').addClass('is-active');
      } else {//hide
        $('body').removeClass('menu-active');
        set_slide_out();
        set_mobile_lang_switcher_animation();
        $this.attr('aria-expanded', 'false').removeClass('is-active');
      }

      return false;

    });


    //WPML ddm click:
    $('a.wpml-ls-item-toggle').click(function(){
      $(this).toggleClass('lang-ddm-active');
    });


    //Page scroll
    $("a.page-scroll").bind("click", function (e) {

      let target = $(this).attr("href");

      //If target is #next get next section after the hero:
      if (target === '#next') {
        target = $('.hero-section').next();
      }

      if (!target.length) {
        return false;
      }

      $("html, body").animate({
        scrollTop: (parseInt($(target).offset().top) - 100)
      }, 700);

      e.preventDefault();

    });


    //Hero image carousel
    if ($('.hero-section-carousel').length) {

      $('.hero-section-carousel').owlCarousel({
        items: 1,
        dots: false,
        nav: true,
        navText: ['<img src="/wp-content/themes/Domine2023/assets/img/icons/chevron-left-white.svg" alt="Arrow left"/>', '<img src="/wp-content/themes/Domine2023/assets/img/icons/chevron-right-white.svg" alt="Arrow left"/>'],
        navContainer: '#owl-nav-container',
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause:true,
        loop: true,
        animateOut: 'fadeOut',
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        lazyLoad: true
      });

    }//end if hero section


    //Trigger te static hero image zoom when the image is loaded
    if ($('div.hero-static-img img').length) {
      imagesLoaded('div.hero-static-img img', {background: false}, function () {
        setTimeout(function () {
          $('div.hero-static-img img').addClass('hero-animated');
        }, 500);
      });
    }


    //Additional images carousel
    if ($('div.extra-images-carousel').length) {

      $('div.extra-images-carousel').each(function () {

        let $this = $(this),
            items = parseInt($this.find('.img-wrap').length),
            max_items = 3;

        if (items > 3) {
          max_items = 4;
        }

        //Check for data-attr items setting:
        if (typeof $this.data('max-images') !== 'undefined') {
          max_items = $this.data('max-images');
        }

        $this.owlCarousel({
          items: max_items,
          nav: true,
          navText: ['<img src="/wp-content/themes/Domine2023/assets/img/icons/chevron-left-white.svg" alt="Arrow left"/>', '<img src="/wp-content/themes/Domine2023/assets/img/icons/chevron-right-white.svg" alt="Arrow left"/>'],
          dots: false,
          responsiveClass: true,
          loop: true,
          margin: 56,
          responsive: {
            0: {
              items: 1,
              nav: false,
              dots: true,
              margin: 0
            },
            768: {
              items: 2,
            },
            1024: {
              items: 3,
            },
            1200: {
              items: 3,
            },
            1366: {
              items: max_items
            }
          }
        });

      });

    }//end if extra images len


    //Logos carousel
    if ($('.logos-carousel').length) {

      let logo_carousel = $('.logos-carousel');
      logo_carousel.each(function(){

        let $this = $(this),
            items = $this.data('items-count');



        $this.owlCarousel({
          items: items,
          nav: false,
          dots: true,
          responsiveClass: true,
          margin: 0,
          responsive: {
            0: {
              items: 2,
              margin: 15
            },
            768: {
              items: 3,
            },
            1024: {
              items: items,
            },
            1200: {
              items: items,
            },
            1366: {
              items: items
            }
          }
        });


      });

    }//end $('.logos-carousel')


    //Post card carousel
    if ($('.post-item-carousel').length) {

      $('.post-item-carousel').owlCarousel({
        items: 1,
        dots: false,
        nav: true,
        margin: 0,
        navText: ['<img src="/wp-content/themes/Domine2023/assets/img/icons/chevron-left-white.svg" alt="Arrow left"/>', '<img src="/wp-content/themes/Domine2023/assets/img/icons/chevron-right-white.svg" alt="Arrow left"/>'],
        loop: true,
        lazyLoad: true
      });

    }//end posts carousel


    //Init Google maps
    if ($('div.theme-map').length) {

      $('.theme-map').each(function () {
        let map = initMap($(this));
      });

    }//end if theme map len


    //Set aria-labels on owl-buttons
    set_owl_buttons_aria();

    //Video popups
    /*$('.video').magnificPopup({
      type: 'iframe',
      preloader: true,
      removalDelay: 500, //delay removal by X to allow out-animation
      callbacks: {
        beforeOpen: function () {
          // just a hack that adds mfp-anim class to markup
          this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
          this.st.mainClass = this.st.el.attr('data-effect');
        }
      },
      closeOnContentClick: true
    });*///end video modal





  });//end DOM loaded


})(jQuery); // Fully reference jQuery after this point.
